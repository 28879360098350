// Here you can add other styles
@import '_variables';
/* VARIABLES */
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1400px;
:root {
}

/* TIPOGRAPHY */
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-italic {
  font-style: italic;
}
.text-vertical-middle {
  vertical-align: middle;
}
.vertical-text-top {
  vertical-align: text-top;
}
.bold {
  font-weight: 700;
}
.text-1rem {
  font-size: 1rem;
}
.danger {
  color:#{$holcim-bright-red};
}
.info {
  color: #{$holcim-dark-blue}
}
.uppercase {
  text-transform: uppercase;
}
.line-heigth-1 {
  line-height: 1;
}

/* BORDER */
.no-border {
  border: none;
}

/* COLORS */
.white {
  color: #ffffff;
}
.black {
  color: #000000;
}
.holcim-dark-blue {
  color: #{$holcim-dark-blue};
}
.holcim-bright-green {
  color: #{$holcim-bright-green};
}
.holcim-bright-red {
  color: #{$holcim-bright-red};
}
.holcim-dark-red {
  color: #{$holcim-dark-red};
}
.holcim-dark-turquoise {
  color: #{$holcim-dark-turquoise};
}

/* BUTTONS */
.holcim-light-btn {
  --cui-btn-color: white;
}
.holcim-dark-btn {
  --cui-btn-color: black;
}
.holcim-no-bg-btn {
  --cui-btn-bg: #{$transparent};
  --cui-btn-border-color: #{$transparent};
  --cui-btn-hover-bg: #{$transparent};
  --cui-btn-hover-border-color: #{$transparent};
  --cui-btn-active-bg: #{$transparent};
  --cui-btn-active-border-color: #{$transparent};
  --cui-btn-disabled-bg: #{$transparent};
  --cui-btn-disabled-border-color: #{$transparent};
}
.holcim-light-btn:hover,
.holcim-light-btn:active,
.holcim-dark-btn:hover,
.holcim-dark-btn:active {
  //opacity: 0.6;
}
.holcim-dark-blue-btn {
  --cui-btn-bg: #{$holcim-dark-blue};
  --cui-btn-border-color: #{$holcim-dark-blue-darker};
  --cui-btn-hover-bg: #{$holcim-dark-blue-darker};
  --cui-btn-hover-border-color: #{$holcim-dark-blue-darker};
  --cui-btn-active-bg: #{$holcim-dark-blue-darker};
  --cui-btn-active-border-color: #{$holcim-dark-blue-darker};
  --cui-btn-disabled-bg: #{$holcim-dark-gray-darker};
  --cui-btn-disabled-border-color: #{$holcim-dark-gray-darker};
}
.holcim-bright-blue-btn {
  --cui-btn-bg: #{$holcim-bright-blue};
  --cui-btn-border-color: #{$holcim-bright-blue-darker};
  --cui-btn-hover-bg: #{$holcim-bright-blue-darker};
  --cui-btn-hover-border-color: #{$holcim-bright-blue-darker};
  --cui-btn-active-bg: #{$holcim-bright-blue-darker};
  --cui-btn-active-border-color: #{$holcim-bright-blue-darker};
  --cui-btn-disabled-bg: #{$holcim-dark-gray-darker};
  --cui-btn-disabled-border-color: #{$holcim-dark-gray-darker};
}
.holcim-bright-green-btn {
  --cui-btn-bg: #{$holcim-bright-green};
  --cui-btn-border-color: #{$holcim-bright-green-darker};
  --cui-btn-hover-bg: #{$holcim-bright-green-darker};
  --cui-btn-hover-border-color: #{$holcim-bright-green-darker};
  --cui-btn-active-bg: #{$holcim-bright-green-darker};
  --cui-btn-active-border-color: #{$holcim-bright-green-darker};
  --cui-btn-disabled-bg: #{$holcim-dark-gray-darker};
  --cui-btn-disabled-border-color: #{$holcim-dark-gray-darker};
}
.holcim-dark-turquoise-btn {
  --cui-btn-bg: #{$holcim-dark-turquoise};
  --cui-btn-border-color: #{$holcim-dark-turquoise-darker};
  --cui-btn-hover-bg: #{$holcim-dark-turquoise-darker};
  --cui-btn-hover-border-color: #{$holcim-dark-turquoise-darker};
  --cui-btn-active-bg: #{$holcim-dark-turquoise-darker};
  --cui-btn-active-border-color: #{$holcim-dark-turquoise-darker};
  --cui-btn-disabled-bg: #{$holcim-dark-gray-darker};
  --cui-btn-disabled-border-color: #{$holcim-dark-gray-darker};
}
.holcim-bright-turquoise-btn {
  --cui-btn-bg: #{$holcim-bright-turquoise};
  --cui-btn-border-color: #{$holcim-bright-turquoise-darker};
  --cui-btn-hover-bg: #{$holcim-bright-turquoise-darker};
  --cui-btn-hover-border-color: #{$holcim-bright-turquoise-darker};
  --cui-btn-active-bg: #{$holcim-bright-turquoise-darker};
  --cui-btn-active-border-color: #{$holcim-bright-turquoise-darker};
  --cui-btn-disabled-bg: #{$holcim-dark-gray-darker};
  --cui-btn-disabled-border-color: #{$holcim-dark-gray-darker};
}
.holcim-dark-gray-btn {
  --cui-btn-bg: #{$holcim-dark-gray};
  --cui-btn-border-color: #{$holcim-dark-gray};
  --cui-btn-hover-bg: #{$holcim-dark-gray-darker};
  --cui-btn-hover-border-color: #{$holcim-dark-gray-darker};
  --cui-btn-active-bg: #{$holcim-dark-gray-darker};
  --cui-btn-active-border-color: #{$holcim-dark-gray-darker};
  --cui-btn-disabled-bg: #{$holcim-bright-gray};
  --cui-btn-disabled-border-color: #{$holcim-bright-gray};
}
button {
  &.holcim-button {
    border-radius: 35px;
    padding: 12px 20px;
    font-size: 12px;
    font-weight: 700;
  }
}
.go-back {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: #{$holcim-dark-blue};
  &:hover {
    --cui-btn-hover-color: #{$holcim-dark-blue};
  }
}

/* MENU */
.sidebar-nav {
  .nav-title {
    font-size: 18px;
  }
  .nav-item {
    a {
      img {
        margin-right: 1rem;
      }
    }
  }
}

/* TOASTER */
.toast {
  &.holcim-toast {
    --cui-toast-box-shadow: unset;
    --cui-toast-border-radius: 0;
    --cui-toast-max-width: 100%;
    &.success {
      --cui-toast-border-color: #{$holcim-bright-green};
      --cui-toast-bg: rgba(148, 193, 46, 0.25);
    }
    &.danger {
      --cui-toast-border-color: #{$holcim-bright-red};
      --cui-toast-bg: rgba(227, 0, 15, 0.25);
    }
  }
}

/* FORMS */
form {
  .form-switch {
    .form-check-input {
      width: 42px;
    }
    .form-check-label {
      padding-top: 0;
      padding-left: 10px;
    }
  }
  &.was-validated {
    .form-check-input {
      &:valid {
        border-color: rgba(0, 0, 21, 0.25);
        &:checked {
          background-color: #{$holcim-bright-green};
          border-color: #{$holcim-bright-green};
        }
        &:focus {
          box-shadow: 0 0 0 0.25rem rgba(148, 193, 46, 0.25);
        }
      }
    }
  }
  &.small-font {
    .form-control {
      font-size: 14px;
    }
    .form-select {
      font-size: 14px;
    }
  }
}
.col-form-label {
  font-size: 16px;
  font-weight: 700;
  --cui-form-label-color: #{$fullblack};
  &.filter-label {
    --cui-form-label-color: #{$holcim-dark-blue};
  }
}
.col-form-value {
  font-size: 14px;
  --cui-form-label-color: #{$fullblack};
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  line-height: 1.5;
}
.form-control {
  --cui-input-bg: white;
  border-radius: 0;
  border-color: #{$holcim-dark-blue};
  &.error {
    border: 1px solid #{$holcim-bright-red};
  }
}
.form-select {
  --cui-form-select-bg: white;
  --cui-form-select-border-color: #{$holcim-dark-blue};
  border-radius: 0;
  background-image:
    linear-gradient(45deg, transparent 50%, white 50%),
    linear-gradient(135deg, white 50%, transparent 50%),
    linear-gradient(to right, #{$holcim-dark-blue}, #{$holcim-dark-blue});
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    100% 0 !important;
  background-size:
    5px 5px,
    5px 5px,
    2.5em 2.5em;
  background-repeat: no-repeat;
  padding: 0.5em 3.5em 0.5em 1em !important;
  &.error {
    border: 1px solid #{$holcim-bright-red};
  }
  &:focus {
    border-color: #{$holcim-dark-blue};
    box-shadow: unset;
  }
}
.react-datetime-picker {
  --cui-form-select-bg: white;
  border: 1px solid #{$holcim-dark-blue};
  padding: 0.4rem 0.2rem;
  border-radius: 0;
  background-image:
    linear-gradient(45deg, #{$holcim-dark-blue}, #{$holcim-dark-blue}),
    linear-gradient(135deg, #{$holcim-dark-blue}, #{$holcim-dark-blue}),
    linear-gradient(to right, #{$holcim-dark-blue}, #{$holcim-dark-blue});
  //background-color: #{$holcim-dark-blue};
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    100% 0 !important;
  background-size:
    5px 5px,
    5px 5px,
    2.5em 2.5em;
  background-repeat: no-repeat;
  .react-datetime-picker__calendar-button {
    &:hover {
      .react-datetime-picker__button__icon {
        stroke: white !important;
      }
    }
    .react-datetime-picker__button__icon {
      stroke: white !important;
      &:hover {
        stroke: white !important;
      }
    }
  }
  >div {
    &[class$="__wrapper"] {
      border: none;
      width: 100%;
      div {
        &[class$="__inputGroup"] {
          //font-size: 0.9rem;
          color: var(--cui-form-select-color, rgba(44, 56, 74, 0.95));
          span {
            padding: 0;
          }
        }
      }
      button {
        padding: 0 6px;
      }
    }
  }
}

/* FORM VALIDATION */

/* TABS */
.holcim-tabs {
  &.nav-tabs {
    --cui-nav-tabs-border-width: 1px;
    //--cui-nav-tabs-border-radius: 0;
    --cui-nav-tabs-link-active-color: white;
    .nav-link {
      //filter: opacity(0.8);
      &.dark-red {
        --cui-nav-tabs-border-color: #{$holcim-dark-red-darker};
        --cui-nav-tabs-link-hover-border-color: #{$holcim-dark-red-darker};
        --cui-nav-tabs-link-active-bg: rgba(#{$holcim-dark-red-rgb}, 0.8);
        --cui-nav-tabs-link-active-border-color: #{$holcim-dark-red-darker};
      }
      &.bright-blue {
        --cui-nav-tabs-border-color: #{$holcim-bright-blue-darker};
        --cui-nav-tabs-link-hover-border-color: #{$holcim-bright-blue-darker};
        --cui-nav-tabs-link-active-bg: rgba(#{$holcim-bright-blue-rgb}, 0.8);
        --cui-nav-tabs-link-active-border-color: #{$holcim-bright-blue-darker};
      }
      &.bright-gray {
        --cui-nav-tabs-border-color: #{$holcim-bright-gray-darker};
        --cui-nav-tabs-link-hover-border-color: #{$holcim-bright-gray-darker};
        --cui-nav-tabs-link-active-bg: rgba(#{$holcim-bright-gray-rgb}, 0.8);
        --cui-nav-tabs-link-active-border-color: #{$holcim-bright-gray-darker};
      }
    }
  }
}

/* TAB PANELS */
.holcim-tab-panels {
  padding: 1rem;
  font-size: 14px;
}

/* COMMON */
a {
  text-decoration: none;
}
.no-pointer {
  cursor: not-allowed;
}
.pointer {
  cursor: pointer;
}
.hssp-divider {
  background-image: linear-gradient(to right,#{$holcim-bright-green},#{$holcim-bright-blue},#{$holcim-dark-blue});
  height: 6px;
  border-top: none;
  margin-bottom: 0;
}
.form-check-input{
  margin-top: 0;
  vertical-align: middle;
}
.holcim-separator {
  border-top: 1px solid #D8D8D8;
}
.holcim-separator-turquoise-dark {
  border-top: 1px solid #{$holcim-dark-turquoise};
}

/* OUTAGE UPDATE BLOCKS */
.outage-update-block {
  border-style: solid;
  border-width: 0 0 0 3px;
  padding-left: 10px;
  border-color: #{$holcim-dark-turquoise};
  .separator {
    padding-top: 5px;
    padding-bottom: 5px;
    >div{
      border-bottom: 1px solid #{$holcim-dark-turquoise};
    }
  }
}

/* CARDS */
.card {
  border-radius: 0;
  border: 1px solid #{$holcim-dark-blue};
}

/* TITLES */
.h1-hssp {
  color: #{$holcim-dark-blue};
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-weight: bold;
  text-transform: uppercase;
}
.h3-hssp {
  color: #{$holcim-dark-turquoise};
  font-size: 1.1rem;
  margin-bottom: 1rem;
  font-weight: bold;
}

/* PAGINATION */
.pagination {
  --cui-pagination-border-color: #{$holcim-dark-blue};
  --cui-pagination-disabled-border-color: #{$holcim-dark-blue};
  --cui-pagination-border-radius: 0;
  --cui-pagination-color: #736363;
  --cui-pagination-hover-color: white;
  --cui-pagination-hover-bg: #{$holcim-dark-blue};
  --cui-pagination-hover-border-color: #{$holcim-dark-blue};
  --cui-pagination-focus-color: white;
  --cui-pagination-focus-bg: #{$holcim-dark-blue};
  --cui-pagination-focus-box-shadow: unset;
  margin-top: 1rem;
  align-items: center;
  justify-content: center;
}

/* HEADER */
.hssp-header {
  .container-fluid {
    justify-content: right;
  }
  padding-bottom: 0;
  .header-brand {
    margin-left: auto;
    img {
      vertical-align: bottom;
    }
  }
  .hssp-subtitle-header {
    color: #{$holcim-dark-blue};
    padding-left: 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 20px;
    text-align: right;
    margin-bottom: 0;
  }
  ul {
    &.hssp-logged-user-nav {
      margin-left: 0 !important;
      display: block;
      text-align: right;
    }
    .hssp-link-logged-user {
      padding-right: 0;
    }
    .hssp-logged-user {
      color: #{$holcim-dark-blue};
      padding-left: 0.5rem;
      font-weight: 500;
      font-size: 14px;
      text-align: right;
    }
    .hssp-dropdown-logged-user {
      width: 100%;
    }
  }
}

/* HSSP PAGE CONTENT */
form {
  &.hssp-content {
    &.row {
      margin-top: var(--cui-gutter-y);
    }
    .form-text {
      color: #{$fullblack};
    }
  }
}
.service-circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  /*margin-left: 10px;*/
  margin-right: 20px;
  display: inline-flex;
  vertical-align: middle;
  + .service-name {
    display: inline-grid;
  }
}
.service-name-1 {
  font-weight: bold;
  color: #{$holcim-text-color};
}
.service-name-2 {
  font-size: 0.8rem;
}
.status-name {
  font-weight: 700;
  svg {
    margin-right: 5px;
  }
}
.maintenance {
  color: #{$holcim-bright-blue};
}
.service-button {
  position: relative;
  display: inline-flex;
  align-items: center;
  &:hover, &:active, &:focus, &:focus-visible, &:focus-within {
    background-color: unset;
    border-color: unset;
  }
  &:after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27var%28--cui-body-color%29%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform 0.2s ease-in-out;
  }
  &:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%231a3c65%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
    transform: rotate(-180deg);
  }
}
.service-button-alternative{
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 0.375rem 0.75rem 0.375rem 0.75rem;
  &:after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-size: 1.25rem;
  }
}
.service-spinner {
  margin-left: 30px;
}
.hssp-card {
  border: none;
  border-radius: 0;
  .card-header {
    border-radius: 0;
    border: unset;
    color: white;
    font-weight: bold;
    background-color: #{$holcim-bright-blue};
    font-size: 20px;
    text-transform: uppercase;
    padding: 1rem;
    >.row {
      //min-height: 60px;
      align-items: center;
    }
  }
  .card-body {
    --cui-card-spacer-y: 0.5rem;
    background-color: white;
    //margin-top: 12px;
    border-left: 6px solid #{$holcim-bright-green};
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    input {
      --cui-form-check-input-checked-bg-color: #{$holcim-bright-green};
      &:checked {
        --cui-form-check-input-checked-border-color: #{$holcim-bright-green};
      }
    }
    .service-countries {
      p {
        column-count: 1;
      }
    }
  }
  .osu-block {
    padding: var(--cui-card-spacer-y) var(--cui-card-spacer-x);
    border: 1px solid #{$holcim-dark-blue};
    //margin-bottom: 3px;
    +.osu-block {
      margin-top: 3px;
    }
  }
  .osu-type-label {
    font-size: 0.9rem;
    color: #{$fullblack};
  }
  .osu-type-value {
    font-weight: bold;
  }
  .osu-date-label {
    font-size: 0.9rem;
    color: #{$fullblack};
  }
  .osu-date-value {
    font-size: 0.9rem;
    color: #{$fullblack};
  }
  .osu-description {
    font-size: 0.9rem;
    text-align: justify;
    color: #{$fullblack};
  }
}

/* TABLES */
.hssp-table-row {
  padding-right: calc(var(--cui-gutter-x) * 0.5);
  padding-left: calc(var(--cui-gutter-x) * 0.5);
  margin-bottom: 8px;
  /*margin-top: 3px;*/
  &.row-header {
    margin-bottom: 5px;
  }
  &.row-utc-info {
    box-shadow: unset;
    text-align: right;
    color: #{$holcim-dark-blue};
    font-size: 14px;
    font-weight: 500;
    line-height: 28.8px;
  }
  >.row {
    min-height: 42px;
    align-items: center;
    color: #{$holcim-text-color};
    background-color: #fff;
    /*border: 1px solid #{$holcim-bright-blue};*/
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    &.header {
      min-height: 50px;
      color: white;
      font-weight: bold;
      background-color: #{$holcim-bright-blue};
      box-shadow: unset;
      font-size: 16px;
      svg {
        polygon {
          stroke: white;
        }
        &.selectedOrder {
          polygon {
            stroke-width: 40px;
          }
        }
      }
    }
    .status-name {
      font-size: 14px;
      padding-top: 3px;
    }
    .status-date {
      font-size: 14px;
      padding-top: 3px;
    }
    input[type=checkbox] {
      border-radius: 0.1em;
      font-size: 20px;
      &:checked {
        --cui-form-check-input-checked-bg-color: black;
        --cui-form-check-input-checked-border-color: black;
      }
    }
  }
  &.hssp-indented-level-1 {
    padding: 0;
    display: none;
    &.desplegado {
      display: block;
    }
    >.col-12 {
      padding-left: 2rem;
    }
    .hssp-card {
      .card-body {
        margin-top: 0;
        padding: 0;
        border: 0;
        box-shadow: unset;
      }
    }
  }
}
.vertical-timeline {
  position: relative;
  padding-left: 45px;
  list-style: none;
  &:before {
    display: inline-block;
    content: '';
    position: absolute;
    top: 0;
    left: 15px;
    width: 10px;
    height: 100%;
  }
  >li {
    position: relative;
    counter-increment: list;
    &:before {
      display: inline-block;
      content: '';
      position: absolute;
      left: -30px;
      height: 100%;
      width: 10px;
      top: 10px;
    }
    &:not(:last-child) {
      padding-bottom: 20px;
      &.default {
        &:before {
          border-left: 2px solid #{$holcim-dark-blue};
        }
      }
      &.offline {
        &:before {
          border-left: 2px solid #{$holcim-bright-red};
        }
      }
      &.performance {
        &:before {
          border-left: 2px solid #{$holcim-yellow};
        }
      }
      &.maintenance {
        &:before {
          border-left: 2px solid #{$holcim-bright-blue};
        }
      }
      &.online {
        &:before {
          border-left: 2px solid #{$holcim-bright-green};
        }
      }
    }
    &.default {
      &:after {
        border: 6px solid #{$holcim-dark-blue};
      }
    }
    &.offline {
      &:after {
        border: 6px solid #{$holcim-bright-red};
      }
    }
    &.performance {
      &:after {
        border: 6px solid #{$holcim-yellow};
      }
    }
    &.maintenance {
      &:after {
        border: 6px solid #{$holcim-bright-blue};
      }
    }
    &.online {
      &:after {
        border: 6px solid #{$holcim-bright-green};
      }
    }
    &:after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 6px;
      left: -35px;
      width: 12px;
      height: 12px;
      border: 6px solid #CCC;
      border-radius: 50%;
      background-color: #FFF;
    }
  }
}

/* CHECKBOXES GRID STYLE */
.gridCheckboxes {
  .flexContainer {
    //display: flex;
    .checkbox {
      padding-left: 0 !important;
      flex: 1;
      label {
        background-color: #{$holcim-bright-gray-darker};
        display: inline-block;
        width: 100%;
        height: 100%;
        z-index: 1;
        position: relative;
        transition: all 250ms ease-out;
        cursor: pointer;
        padding: 10px;
        font-size: 14px;
      }
      input[type=checkbox] {
        display: none;
      }
      input[type=checkbox]:checked {
        + label {
            background-color: #{$holcim-green};
        }
      }
    }
  }
}
/* FOOTER */
.footer {
  --cui-footer-border: none;
  --cui-footer-min-height: 98px;
  .social_wrapper {
    display: flex;
    flex-direction: row;

    .icon-rrss {
      background: #ffffff 0 0 no-repeat padding-box;
      width: 39px;
      height: 37px;
      border-radius: 25px;
      position: relative;
      margin-right: 0.625rem;

      svg{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
      }
    }
  }
}

/* PUBLIC PAGE SPECIAL STYLE */
.public-view {
  background-color: white;
  .hssp-table-row {
    >.row {
      /*border: 1px solid #{$holcim-dark-blue};*/
      &.header {
        background-color: #{$holcim-dark-blue};
      }
    }
  }
  .hssp-card {
    .card-header {
      background-color: #{$holcim-dark-blue};
    }
    .card-body {
      border: 1px solid #{$holcim-dark-blue};
    }
  }
  .outage-update-block {
    border-color: #{$holcim-dark-blue};
    .separator {
      >div{
        border-bottom: 1px solid #{$holcim-dark-blue};
      }
    }
  }
}

/* SPINNER */
.body-relative {
  position: relative;
}
.spinner-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,0.5);
  z-index: 1000;
  .spinner-content {
    position: absolute;
    .spinner-border {
      border: 0.25em solid white;
      border-right-color: transparent;
    }
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* TOOLTIP */
.holcim-tooltip {
  position: relative;
  display: inline-block;
  &:hover {
    .holcim-white-tooltip {
      visibility: visible;
    }
  }
  .holcim-white-tooltip {
    visibility: hidden;
    width: 300px;
    bottom: 100%;
    left: 0;
    margin-left: -150px;
    background-color: white;
    color: black;
    text-align: center;
    padding: 5px;
    position: absolute;
    z-index: 1;
    font-weight: normal;
    font-size: small;
    border: 1px solid #{$holcim-dark-blue};
  }
}

/* RESPONSIVE */
@media(min-width: $breakpoint-sm) {
  .hssp-card {
    .card-body {
      .service-countries {
        p {
          column-count: 2;
        }
      }
    }
  }
}
@media(min-width: $breakpoint-md) {
  .px-5-md {
    padding: 3rem !important;
  }
  .hssp-card {
    .card-body {
      .service-countries {
        p {
          column-count: 2;
        }
      }
    }
  }
}
@media(min-width: $breakpoint-lg) {
  .hssp-card {
    .card-body {
      .service-countries {
        p {
          column-count: 3;
        }
      }
    }
  }
}
@media(min-width: $breakpoint-xl) {
  .hssp-card {
    .card-body {
      .service-countries {
        p {
          column-count: 4;
        }
      }
    }
  }
}
@media(min-width: $breakpoint-xxl) {
}

